// src/pages/ServicePage.jsx
import React from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate from react-router-dom
import "./WebList.css"; // Add your custom CSS for styling and animations

const WebList = () => {
  const navigate = useNavigate(); // Get navigate function from useNavigate

  const services = [
    { 
      name: "Auto Backup", 
      imgSrc: "/images/autobackupimg.jpg", 
      description: "A robust desktop application designed to automate business processes efficiently, tailored to meet the specific needs of your organization.",
      path: "/autoBackupSoftware" // Add a path for Auto Desktop Application
    },
    { 
      name: "E-Warehouse Data Storage", 
      imgSrc: "/images/Screenshot (108).png", 
      description: "A secure cloud-based data storage solution, allowing businesses to manage and store data seamlessly while ensuring maximum security and availability.",
      path: "/e_data" // Path for E-warehouse
    },
    { 
      name: "Purchase Request Modules ", 
      imgSrc: "/images/service/Screenshot (121).png", 
      description: "A comprehensive module to streamline the purchase request process, improving efficiency and reducing manual workload.",
      path: "/pr" // Path for Purchase Request Module Application
    },
    { 
      name: "Logistics modules ", 
      imgSrc: "/images/service/image.png", 
      description: "A transport management system that simplifies logistics and fleet tracking, offering real-time updates and efficient management tools.",
      path: "/tr" // Path for Transport Web Application
    },
  ];

  const handleServiceClick = (service) => {
    navigate(service.path); // Navigate to the service path
  };

  return (
    <div className="web-page">
      <br/>
      <br/>

   
      <h1>Our Services</h1>
      <div className="web-container">
        {services.map((service, index) => (
          <div key={index} className="web-card" onClick={() => handleServiceClick(service)}>
            <img src={service.imgSrc} alt={service.name} className="service-img" />
            <h2>{service.name}</h2>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WebList;
